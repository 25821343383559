<script>
import Layout from "@/router/layouts/main";
import appConfig from "@/app.config";
import PageHeader from "@/components/page-header";
import Datatable from "@/router/layouts/table-datatable.vue";
import Pagination from "@/router/layouts/table-pagination.vue";
import AddBatch from "@/router/views/dashboards/batch/add-batch.vue";
import EditBatch from "@/router/views/dashboards/batch/edit-batch.vue";
import axios from "axios";
import Swal from "sweetalert2";

export default {
  page: {
    title: "Dashboard",
    meta: [
      {
        name: "description",
        content: appConfig.description,
      },
    ],
  },
  components: {
    Layout,
    PageHeader,
    Datatable,
    Pagination,
    AddBatch,
    EditBatch,
  },
  data() {
    let sortOrders = [];
    let columns = [
      { width: "50px", label: "No", name: "id_batch" },
      { width: "auto", label: "Nama Batch", name: "nama_batch" },
      { width: "auto", label: "Simulasi", name: "-" },
      { width: "auto", label: "Tanggal & Waktu Mulai", name: "tanggal_awal" },
      {
        width: "auto",
        label: "Tanggal & Waktu Selesai",
        name: "tanggal_akhir",
      },
      { width: "150px", label: "Aksi", name: "-" }, // jika tidak ada sorting kasih name dengan karakter -ka tidak ada sorting kasih name dengan karakter -
    ];
    return {
      title: "List Batch",
      items: [
        {
          text: "Dashboard",
          href: "/",
        },
        {
          text: "List Batch",
          href: "/batch",
          active: true,
        },
      ],
      isShowForm: false,
      isShowFormEdit: false,
      idEdit: 0,
      idJadwal: this.$route.params.idJadwal,
      level: this.$route.query.level,

      // variable data
      dataJadwal: {},
      simulasi_data: [],
      simulasi_value: [],

      // data table
      loadingTable: true,
      table_data: [],
      columns: columns,
      sortKey: "id", // sort key awal
      sortOrders: sortOrders,
      currentTablePage: "",
      tableData: {
        draw: 0,
        per_page: 10,
        search: "",
        sort_field: "id_batch",
        sort_order: "desc",
        filter_jadwal: this.$route.params.idJadwal,
        filter_simulasi: "", // untuk filtering data
      },
      pagination: {
        lastPage: "",
        currentPage: "",
        total: "",
        lastPageUrl: "",
        nextPageUrl: "",
        prevPageUrl: "",
        from: "",
        to: "",
        links: "",
      },
    };
  },
  mounted() {
    this.getJadwal();
    this.getSimulasi();
    this.getDataTable();
  },
  methods: {
    getJadwal(
      url = process.env.VUE_APP_BACKEND_URL_VERSION +
        "dashboard/jadwal/" +
        this.idJadwal
    ) {
      let self = this;
      let config = {
        method: "get",
        url: url,
        headers: {
          Accept: "application/json",
          Authorization: "Bearer " + localStorage.access_token,
          access: "all-role",
          role_id: localStorage.session_role_active_id,
        },
      };
      axios(config)
        .then((response) => {
          let response_data = response.data;
          self.dataJadwal = response_data.data;
          console.log(self.dataJadwal);
        })
        .catch((errors) => {
          console.log(errors);
        });
    },
    getDataTable(
      url = process.env.VUE_APP_BACKEND_URL_VERSION + "transaction/batch"
    ) {
      let self = this;
      self.loadingTable = true;
      self.tableData.draw++;

      var config = {
        method: "get",
        url: url,
        params: self.tableData,
        headers: {
          Accept: "application/json",
          Authorization: "Bearer " + localStorage.access_token,
          access: "all-role",
          role_id: localStorage.session_role_active_id,
        },
      };

      axios(config)
        .then((response) => {
          let response_data = response.data;
          let response_data_fix = response_data.data;
          if (response_data.meta.code == 200) {
            self.table_data = response_data_fix.list;
            console.log(self.table_data);
            self.configPagination(response_data_fix.pagination);
            self.currentTablePage = response_data_fix.pagination.current_page;
            self.loadingTable = false;
          } else {
            Swal.fire({
              icon: "error",
              title: "Oops...",
              text: response_data_fix.data.message,
            });
          }
        })
        .catch((errors) => {
          console.log(errors);
        });
    },
    configPagination(data) {
      let self = this;
      self.pagination.lastPage = data.last_page;
      self.pagination.currentPage = data.current_page;
      self.pagination.total = data.total;
      self.pagination.lastPageUrl = data.last_page_url;
      self.pagination.nextPageUrl = data.next_page_url;
      self.pagination.prevPageUrl = data.prev_page_url;
      self.pagination.from = data.from;
      self.pagination.to = data.to;
      self.pagination.links = data.links;
    },
    sortBy(key) {
      let self = this;
      self.sortKey = key;
      self.sortOrders[key] = self.sortOrders[key] * -1;
      self.tableData.column = self.getIndex(self.columns, "name", key);
      self.tableData.dir = self.sortOrders[key] === 1 ? "asc" : "desc";
      self.getDataTable();
    },
    toPage: function (str) {
      let self = this;
      self.getDataTable(str);
    },
    getIndex(array, key, value) {
      return array.findIndex((i) => i[key] == value);
    },
    filterSimulasiData() {
      let self = this;
      let simulasi_id = self.simulasi_value?.simulasi_id;
      self.tableData.filter_simulasi = simulasi_id;
      self.getDataTable();
    },
    resetFilterTable() {
      let self = this;
      self.simulasi_value = [];
      self.tableData.filter_simulasi = "";
      self.getDataTable();
    },
    getSimulasi(url = process.env.VUE_APP_BACKEND_URL_VERSION + "referensi/simulasi-jadwal") {
      let self = this;
      let config = {
        method: "get",
        url: url, 
        params: {
          idJadwal: self.idJadwal,
        },
        headers: {
          Accept: "application/json",
          Authorization: "Bearer " + localStorage.access_token,
          access: "all-role",
          role_id: localStorage.session_role_active_id,
        },
      };
      axios(config)
        .then((response) => {
          let response_data = response.data;
          self.simulasi_data = response_data.data.referensi;
        })
        .catch((errors) => {
          console.log(errors);
        });
    },
    addBatch() {
      let self = this;
      self.isShowForm = true;
      self.isShowFormEdit = false;
    },
    editBatch(id) {
      let self = this;
      self.idEdit = id;
      self.isShowForm = false;
      self.isShowFormEdit = true;
    },
    hideform() {
      let self = this;
      self.isShowForm = false;
      self.isShowFormEdit = false;
      self.getDataTable();
    },
    deleteBatch(id) {
      let self = this;
      Swal.fire({
        title: "Perhatian",
        text: "Anda yakin ingin menghapus data tersebut?",
        showDenyButton: true,
        showCancelButton: false,
        confirmButtonText: `Iya`,
        denyButtonText: `Tidak`,
      }).then((result) => {
        if (result.isConfirmed) {
          self.loadingTable = true;
          Swal.fire({
            title: '<i class="fa fa-refresh fa-spin"></i>',
            text: "Loading...",
            showConfirmButton: false,
          });

          var config = {
            method: "delete",
            url:
              process.env.VUE_APP_BACKEND_URL_VERSION +
              "transaction/batch/" +
              id,
            headers: {
              Accept: "application/json",
              Authorization: "Bearer " + localStorage.access_token,
            },
          };
          axios(config).then((response) => {
            var response_data = response.data;
            if (response_data.meta.code != "200") {
              Swal.fire({
                icon: "error",
                title: "Oops...",
                text: response_data.data.message,
              });
            } else {
              let timerInterval;
              Swal.fire({
                icon: "success",
                title: "Berhasil",
                text: "Data berhasil dihapus.",
                timer: 2000,
                timerProgressBar: true,
                showCancelButton: false,
                showConfirmButton: false,
                didOpen: () => {
                  timerInterval = setInterval(() => {
                    const content = Swal.getContent();
                    if (content) {
                      const b = content.querySelector("b");
                      if (b) {
                        b.textContent = Swal.getTimerLeft();
                      }
                    }
                  }, 100);
                },
                willClose: () => {
                  clearInterval(timerInterval);
                },
              }).then((result) => {
                /* Read more about handling dismissals below */
                if (result.dismiss === Swal.DismissReason.timer) {
                  self.getDataTable();
                }
              });
            }
          });
        } else {
          Swal.close();
        }
      });
    },
  },
};
</script>

<template>
  <Layout>
    <template v-if="isShowForm == false && isShowFormEdit == false">
      <PageHeader :title="title" :items="items" />
      <div class="row">
        <div class="col-lg-12">
          <div class="card">
            <div class="card-body">
              <div class="row">
                <div class="col-md-12">
                  <div class="row">
                    <div class="col-6">
                      <div class="row">
                        <div class="col-10">
                          <div class="form-group">
                            <v-select
                              v-model="simulasi_value"
                              :options="simulasi_data"
                              @option:selected="filterSimulasiData()"
                              id="simulasi_id"
                              label="simulasi_nama"
                              placeholder="Filter Simulasi"
                            ></v-select>
                          </div>
                        </div>
                        <div class="col-2 pt-1">
                          <button
                            type="button"
                            class="btn btn-sm btn-secondary"
                            @click="resetFilterTable()"
                          >
                            <i class="bx bx-reset"></i> Reset Filter
                          </button>
                        </div>
                      </div>
                    </div>
                    <div class="col-6">
                      <div class="text-end">
                        <router-link
                          :to="{
                            name: 'setting-jadwal',
                            params: { id: idJadwal },
                          }"
                          class="btn btn-sm btn-warning"
                        >
                          <i class="fa fa-arrow-left"></i> Kembali ke Detail
                          Jadwal
                        </router-link>
                        &nbsp;
                        <button
                          type="button"
                          class="btn btn-sm btn-success"
                          @click="addBatch"
                        >
                          <i class="fa fa-plus"></i> Tambah
                        </button>
                      </div>
                    </div>
                  </div>
                  <hr />
                </div>
                <div class="col-md-12 mb-2">
                  <div class="row">
                    <div class="col-md-4">
                      <table>
                        <tr>
                          <td>Show</td>
                          <td>
                            <select
                              class="form-control form-control-sm"
                              id="showpaginatetable"
                              style="width: 100%"
                              v-model="tableData.per_page"
                              @change="getDataTable()"
                            >
                              <option value="10" selected>10</option>
                              <option value="50">50</option>
                              <option value="100">100</option>
                              <option value="200">200</option>
                              <option value="500">500</option>
                            </select>
                          </td>
                          <td>Entries</td>
                        </tr>
                      </table>
                    </div>
                    <div class="col-md-5">&nbsp;</div>
                    <div class="col-md-3">
                      <input
                        type="text"
                        class="form-control form-control-sm"
                        id="searchTable"
                        placeholder="Cari Data ..."
                        v-model="tableData.search"
                        @input="getDataTable()"
                      />
                    </div>
                  </div>
                </div>
                <datatable
                  :columns="columns"
                  :sortKey="sortKey"
                  :sortOrders="sortOrders"
                  @sort="sortBy"
                >
                  <tbody>
                    <tr v-if="loadingTable">
                      <td colspan="6">
                        <i class="fas fa-spinner fa-spin"></i> Loading...
                      </td>
                    </tr>
                    <tr v-else-if="table_data == ''">
                      <td class="text-center" colspan="6">
                        Data Tidak Tersedia
                      </td>
                    </tr>
                    <tr
                      v-else
                      v-for="(row_data, key_data) in table_data"
                      :key="key_data"
                    >
                      <td class="text-center">
                        <div v-if="key_data + 1 == 10">
                          {{ currentTablePage }}0.
                        </div>
                        <div v-else>
                          {{
                            currentTablePage - 1 != 0
                              ? currentTablePage - 1
                              : ""
                          }}{{ key_data + 1 }}.
                        </div>
                      </td>
                      <td>{{ row_data.nama_batch }}</td>
                      <td>{{ row_data.simulasi_nama }}</td>
                      <td class="text-center">{{ row_data.tanggal_awal }}</td>
                      <td class="text-center">{{ row_data.tanggal_akhir }}</td>
                      <td class="text-center">
                        <div class="btn-group btn-group-sm">
                          <button
                            class="btn btn-info btn-sm"
                            @click="editBatch(row_data.id_batch)"
                          >
                            <i class="bx bx-edit"></i> Edit
                          </button>
                          <button
                            class="btn btn-danger btn-sm"
                            @click="deleteBatch(row_data.id_batch)"
                          >
                            <i class="bx bx-trash"></i> Delete
                          </button>
                        </div>
                      </td>
                    </tr>
                  </tbody>
                </datatable>
              </div>
              <pagination
                :pagination="pagination"
                @to-page="toPage"
              ></pagination>
            </div>
          </div>
        </div>
      </div>
    </template>
    <template v-else-if="isShowForm == false && isShowFormEdit == true">
      <EditBatch
        v-if="isShowFormEdit == true"
        @hideForm="hideform"
        :id="idEdit"
        :idJadwal="idJadwal"
        :tanggalMulai="dataJadwal.j_tanggal_mulai"
        :tanggalSelesai="dataJadwal.j_tanggal_sampai"
      />
    </template>
    <template v-else-if="isShowForm == true && isShowFormEdit == false">
      <AddBatch
        v-if="isShowForm == true"
        @hideForm="hideform"
        :idJadwal="idJadwal"
        :level="this.$route.params.level"
        :tanggalMulai="dataJadwal.j_tanggal_mulai"
        :tanggalSelesai="dataJadwal.j_tanggal_sampai"
      />
    </template>
  </Layout>
</template>
